document.addEventListener('DOMContentLoaded', () => {

	


	/*lang*/
	const url = window.location;
	var data;
	let params = new URLSearchParams(url.search);
	let lang = params.get('lang');

    const translatePage = (obj) => {


    	let container = document.body,
	    elems = container.getElementsByTagName("*"),
	    len = elems.length,
	    elem,
	    elemText,
	    i,
	    unwanted = ["script", "images", "imput"];
		for(i=0;i<len;i+=1){
		  elem = elems[i];
		  if(unwanted.indexOf(elem.nodeName.toLowerCase())=="-1"){

		  	let text = Array.prototype.filter.call(elem.childNodes, (child) => child.nodeType === Node.TEXT_NODE)
		  	.map((child) => child.textContent).join('');
		  	
		  	text = text.trim();
		    if(text.length){
		      elemText = elem.innerHTML.trim();
		      if(obj[lang]){
			      if(elemText && obj[lang][elemText]){
			        elem.innerHTML = obj[lang][elemText];
			      }
		      }
		     
		    }
		  }
		}
    }

    
    const mainScripts = () => {

    	/*pattern*/

		for (const el of document.querySelectorAll("[pattern-mask][data-slots]")) {
	        const pattern = el.getAttribute("pattern-mask"),
	            slots = new Set(el.dataset.slots || "_"),
	            prev = (j => Array.from(pattern, (c,i) => slots.has(c)? j=i+1: j))(0),
	            first = [...pattern].findIndex(c => slots.has(c)),
	            accept = new RegExp(el.dataset.accept || "\\d", "g"),
	            clean = input => {
	                input = input.match(accept) || [];
	                return Array.from(pattern, c =>
	                    input[0] === c || slots.has(c) ? input.shift() || c : c
	                );
	            },
	            format = () => {
	                const [i, j] = [el.selectionStart, el.selectionEnd].map(i => {
	                    i = clean(el.value.slice(0, i)).findIndex(c => slots.has(c));
	                    return i<0? prev[prev.length-1]: back? prev[i-1] || first: i;
	                });
	                el.value = clean(el.value).join``;
	                el.setSelectionRange(i, j);
	                back = false;
	            };
	        let back = false;
	        el.addEventListener("keydown", (e) => back = e.key === "Backspace");
	        el.addEventListener("input", format);
	        el.addEventListener("focus", format);
	        el.addEventListener("blur", () => el.value === pattern && (el.value=""));
	    }



		/*SELECT BOX*/
		var selectBoxes, selectedIndex, dropdownItem, dropdownItemIcon, select, selectClass, nodes;
		selectBoxes = document.querySelectorAll(".select-box");

		selectBoxes.forEach( item => {
			const isMulti = item.classList.contains('multiselect') ? true : false;
			const isColorPicker = item.classList.contains('colorpicker') ? true : false;
			select = item.querySelector("select");
			options = select.querySelectorAll('option');

			selectedOpt = document.createElement('div');
			selectedOpt.setAttribute('class', 'select-selected');


			const fillSelect = (options, selectedOptions) => {
				options.forEach((item, index) => {
					let optionsAll = [...options].filter(function(item){ 
						return item.selected;
					});

					let optionsSelected = [...options].filter(function(item){ 
						return !item.classList.contains('all') && !item.disabled;
					});

					let allOption = [...options].filter((item) => {
						return item.classList.contains('all');
					});

					if(item.selected){
						if (isColorPicker){
							selectedOptions.innerHTML += "<span>"+"<i style='background: "+item.getAttribute('data-color')+"'></i>"+item.innerHTML+"</span>";
						}else{
							if (optionsAll.length == optionsSelected.length 
									&& optionsAll.length > 1
									&& allOption.length > 0){
								selectedOptions.innerHTML = "<span>"+allOption[0].innerText+"</span>";
							}else{
								if (selectedOptions.innerHTML.length != 0){
									selectedOptions.innerHTML += ", <span>"+item.innerHTML+"</span>";
								}else{
									selectedOptions.innerHTML += "<span>"+item.innerHTML+"</span>";
								}
							}
						}
					}
				});
			}
			
			if(isMulti){
				fillSelect(options, selectedOpt);
			}else{
				selectedIndex = select.options[select.selectedIndex];
				selectedOpt.innerHTML = selectedIndex.getAttribute("selected") == null ? '' : selectedIndex.innerHTML;
			}		

			item.appendChild(selectedOpt);
			if(!isMulti){
				let extraClasses = [...selectedIndex.classList].filter(item => !item.includes('option') && !item.includes('selected'));
				if(extraClasses){
					extraClasses.forEach(classValue => item.querySelector('.select-selected').classList.add(classValue));
				}
			}
			dropdownItems = document.createElement("div");
			dropdownItems.setAttribute("class", "select-items select-hide");


			options.forEach((item, index) => {
				dropdownItem = document.createElement("div");
				const dropdownItemClassList = ['option'];

				item.classList.forEach(item => dropdownItemClassList.push(item));
				if (item.selected){ dropdownItemClassList.push('selected') }
				if (item.disabled){ dropdownItemClassList.push('disabled') }

				dropdownItem.setAttribute('class', dropdownItemClassList.join(' '));
				dropdownItemIcon = document.createElement("span");
				
				if (isColorPicker){
					dropdownItem.innerHTML += '<i style="background: '+options[index].getAttribute('data-color')+'"></i>';
				}

				dropdownItem.innerHTML += item.innerHTML;
				

				dropdownItem.addEventListener('click', (e) => {
					const el = e.target;
					const selectBox = el.closest('.select-box');
					const selectSelected = selectBox.querySelector('.select-selected');
					const select = selectBox.querySelector("select");
					const options = select.querySelectorAll('option');


					

					if(isMulti){
						e.stopPropagation();

						if (item.classList.contains("all")){

							e.target.closest('.select-items').querySelectorAll('.option').forEach(item => {
								if (!item.classList.contains('all') 
									&& !item.classList.contains('disabled')
									&& !item.classList.contains('selected')){
									item.click();
								}
							});

							

						}else{
							if(item.selected){
								options[index].removeAttribute('selected');
								options[index].selected = false;
								el.classList.remove('selected');
							}else{
								options[index].setAttribute('selected', 'selected');
								options[index].selected = true;
								el.classList.add('selected');
							}

							selectSelected.innerHTML = "";

							fillSelect(options, selectSelected);
						}

						

					}else{
						select.querySelectorAll('option').forEach( item => item.removeAttribute('selected'));
						selectSelected.innerHTML = el.innerHTML;
						let extraClasses = [...el.classList].filter(item => !item.includes('option') && !item.includes('selected'));
						if(extraClasses){
							selectSelected.classList = 'select-selected';
							extraClasses.forEach(item => selectSelected.classList.add(item));
						}
						selectBox.querySelectorAll('.option').forEach(item => item.classList.remove('selected'));
						options[index].selected = true;
						options[index].setAttribute('selected', 'selected');
						el.classList.add('selected');
						closeAllSelect();
					}


					if(selectBox.parentNode.classList.contains('dynamic-label-holder')){
				    	if (selectBox.querySelector('.select-selected').innerHTML.trim() == "") {
				    		selectBox.parentNode.classList.add('empty');
				    	} else{
				    		selectBox.parentNode.classList.remove('empty');
				    	}
				    }

				    select.dispatchEvent(new Event('change', {'view': window,'bubbles': true}));

				    el.closest('.select-box').parentNode.classList.remove('focus');
				});

				dropdownItems.appendChild(dropdownItem);
			});

			item.appendChild(dropdownItems);

			selectedOpt.addEventListener("click", function(e) {
			    e.stopPropagation();
			    const items = e.target.nextSibling;
			    const selectHide = items.classList.contains('select-hide');
			    const labelBox = e.target.closest('.label-box');
			    closeAllSelect(e.target);
			    if (selectHide) {
			    	items.classList.remove("select-hide")
			    	e.target.classList.add("select-arrow-active");
			    	if (labelBox){
			    		labelBox.classList.add('active');
			    	}
			    } else{
			    	items.classList.add("select-hide");
			    	e.target.classList.remove("select-arrow-active");
			    	if (labelBox){
			    		labelBox.classList.remove('active');
			    	}
			    }

			    if (e.target.closest('.select-box').parentNode.classList.contains('dynamic-label-holder')){
			    	e.target.closest('.select-box').parentNode.classList.add('focus');
			    }
			});
		});

		const closeAllSelect = (e) => {
			const items = document.querySelectorAll(".select-items");
			const selected = document.querySelectorAll(".select-selected");
			items.forEach( item => item.classList.add('select-hide'));
			selected.forEach( item => {
				const labelBox = item.closest('.label-box');
				if (labelBox) {
		    		labelBox.classList.remove('active');
		    	}
				item.classList.remove('select-arrow-active');
			});
			document.querySelectorAll('.dynamic-label-holder').forEach(item => item.classList.remove('focus'));
		}
		document.addEventListener("click", closeAllSelect);

		/*END SELECTBOX*/


		/*Bullet slider*/
		const bulletSlider = document.querySelectorAll('.bullet-slider');
		if (bulletSlider) {
			let bullets;

			

			bulletSlider.forEach( (sliderItem) => {
				let sliders = sliderItem.querySelectorAll('.slide');

				const sliderBulletClicked = (index) => {
					sliders.forEach(item => item.classList.remove('active'));
					sliders[index].classList.add('active');
					sliderItem.querySelectorAll('.bullet').forEach(item => item.classList.remove('active'));
					sliderItem.querySelectorAll('.bullet')[index].classList.add('active');
				}


				bullets = sliderItem.querySelectorAll('.bullet');
				bullets.forEach((item, index) => {
					item.addEventListener('click', function() {
						sliderBulletClicked(index)
					})
				});
			});

			
		}

		/*toggle-row*/

		const toggleRow = document.querySelectorAll('.toggle-row a');
		const toggleRowLinkClickListenter = (e) => {
			if(e.target.closest('.panel').querySelector('.row-to-toggle')){
				e.target.closest('.panel').querySelector('.row-to-toggle').classList.remove('hidden-row');
			}
			
			if(e.target.closest('.toggle-row')){
				e.target.closest('.toggle-row').classList.add('hidden-row');
			}
		}
		toggleRow.forEach(item => item.addEventListener('click', toggleRowLinkClickListenter));


		/*SLIDER*/
		const slider = document.querySelectorAll('.slider-holder');
		if(slider){
			
			slider.forEach( (sliderItem) => {

				const track = sliderItem.querySelector('.items');
				const slide = sliderItem.querySelectorAll('.item');
				let slideWidth;
				
				const arrR = sliderItem.querySelector(':scope > .arrows .arrow-right');
				const arrL = sliderItem.querySelector(':scope > .arrows .arrow-left');

				
				if(arrR){
					arrR.addEventListener('click', (e) => {
						let current = getCurrentSlide();
						let el = slide[current];
						if (current < slide.length-1){
							updateTrack(el, current, 'r');
						}

						updArrowVisibility();
					});
				}
				
				if(arrL){
					arrL.addEventListener('click', (e) => {
						let current = getCurrentSlide();
						let el = slide[current];
						if (current > 0){
							updateTrack(el, current, 'l');
						}

						updArrowVisibility();
					});
				}
				

				const updArrowVisibility = () => {
					let c = getCurrentSlide();

					if(arrR){
						if (c >= slide.length-1){
							arrR.classList.add('hidden');
						}else {
							arrR.classList.remove('hidden');
						}
					}
					
					if(arrL){
						if (c == 0){
							arrL.classList.add('hidden');
						}else {
							arrL.classList.remove('hidden');
						}
					}
					
				}

				const updateTrack = (e, c, d) => {
					
					let w = 0, condition;

					slide.forEach((j, i) => { 
						if (d == 'l'){
							if (i+1 < c){
								w += calcElWidth(j);
							}
						}else if(d == 'r'){
							if (i <= c){
								w += calcElWidth(j);
							}
						}else{
							if (i<c){
								w+= calcElWidth(j);
							}
						}
					});
					
					track.style.transform = 'translateX(-'+w+'px)';
					if (d == 'l'){
						e.classList.remove('active');
						e.previousElementSibling.classList.add('active')
					} else if (d == 'r'){
						e.classList.remove('active');
						e.nextElementSibling.classList.add('active');
					}
				}

				const calcElWidth = (j) => {
					let style = j.currentStyle || window.getComputedStyle(j);
					let margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
					return j.offsetWidth + margin;
				}

				const getCurrentSlide = () => {
					let current;
					slide.forEach((slideItem, index) => {
						if (slideItem.classList.contains('active')) {
							current = index;
						}
					});
					return current;
				}

				const initActiveSlide = () => {
					let current = getCurrentSlide();
					let el = slide[current];
					updateTrack(el, current, 'c');
					updArrowVisibility();
				}
				initActiveSlide();


			});
		}
		/*END SLIDER*/


		const replyBtn = document.querySelectorAll('.review-btn');

		const replyBtnClickHandler = (e) => {
			document.querySelectorAll('.reply-review').forEach(item => item.classList.remove('active'));
			e.target.closest('.comment-body').querySelector(':scope > .reply-review').classList.add('active');
		}

		replyBtn.forEach(item => item.addEventListener('click', replyBtnClickHandler))

		//Gallery img scale

		const scaleImgGallery =  document.querySelectorAll('.scale-img-gallery');

		scaleImgGallery.forEach(gallery => {
			const galleryItems = gallery.querySelectorAll('.item .item-img');
			const galleryItemMouseMoveHandler = (event) => {
				const imgToShow = event.target.closest('.slider').querySelector(':scope > img.default');
				if(imgToShow){
					imgToShow.style.left = event.clientX + 'px';
					imgToShow.style.top = event.clientY + 'px';
					imgToShow.style.position = 'fixed';
					imgToShow.style.display = 'block';
				}
			}
			galleryItems.forEach(item => item.addEventListener('mousemove', galleryItemMouseMoveHandler));

			const galleryItemMouseOverHandler = (event) => {
				const imgToShow = event.target.closest('.item').querySelector('.item-img .default');
				if(imgToShow){
					event.target.closest('.slider').appendChild(imgToShow.cloneNode(true));
				}
			}
			galleryItems.forEach(item => item.addEventListener('mouseover', galleryItemMouseOverHandler));

			const galleryItemMouseOutHandler = (event) => {
				if(event.target.closest('.slider').querySelector(':scope > img.default')){
					event.target.closest('.slider').querySelector(':scope > img.default').remove();
				}
			}
			galleryItems.forEach(item => item.addEventListener('mouseout', galleryItemMouseOutHandler));
		});


		/*Scroll slider*/

		const scrollSliders = document.querySelectorAll('.scroll-slider');
		if(scrollSliders.length>0){
			scrollSliders.forEach(scrollSlider => {

				const scrollSliderItemClickHandler = (e) => {
					e.preventDefault();
					if(e.target.closest('a')){
						window.location.href = window.location = e.target.closest('a').getAttribute('href');
					}
				}

				if (scrollSlider.querySelectorAll('.item a').length > 0){
					scrollSlider.querySelectorAll('.item a').forEach(item => { 
						item.addEventListener('click', scrollSliderItemClickHandler);
					});
				}

				const images = document.querySelectorAll('.item-img');
				let isDown = false;
				let startX;
				let scrollLeft;

				images.forEach(img => img.ondragstart = () => { return false })

				scrollSlider.addEventListener('mousedown', (e) => {
				  isDown = true;
				  startX = e.pageX - scrollSlider.offsetLeft;
				  scrollLeft = scrollSlider.scrollLeft;
				});
				scrollSlider.addEventListener('mouseleave', () => {
				  isDown = false;
				  scrollSlider.classList.remove('active');
				});
				scrollSlider.addEventListener('mouseup', () => {
				  isDown = false;
				  scrollSlider.classList.remove('active');
				});
				scrollSlider.addEventListener('mousemove', (e) => {
				  if(!isDown) return;
				  e.preventDefault();
				  scrollSlider.classList.add('active');
				  const x = e.pageX - scrollSlider.offsetLeft;
				  const walk = (x - startX) * 2; //scroll-fast
				  scrollSlider.scrollLeft = scrollLeft - walk;
				});
			
			});
		}

		

		/*END SCROLL SLIDER*/

		const togglePanelBtn = document.querySelectorAll('.toggle-panel-btn');

		const togglePanelBtnClickHandler = (e) => {
			let panel = e.target.closest('.toggle-panel-btn').getAttribute('for');
			e.target.closest('.main').classList.toggle('filter-opened');
			document.querySelector(panel).classList.toggle('show');
		}
		togglePanelBtn.forEach(item => item.addEventListener('click', togglePanelBtnClickHandler));



		/*================PHOTOEDITOR==============*/

		/*add new item*/
	    const fileInput = document.querySelectorAll('.fileinput-box.fileinput-box-crop input[type=file]');

	    const changeFileInputHandler = (e) => {
	        const imgItem = e.target.closest('.fileinput-box').querySelector('.file-name');
	        let cropper = '';

	        if (e.target.files[0]) {
	            const modalID = e.target.closest('.fileinput-box').querySelector('.file-name').getAttribute('data-popup');
	            const modal = document.querySelector("#"+modalID);
	            const picture = new FileReader();

	            if(modal){
		            picture.readAsDataURL(e.target.files[0]);

		            picture.addEventListener('load', function(event) {
		                const modalImg = modal.querySelector('.modal-preview img');

		                modalImg.setAttribute('src', event.target.result);
		                modalImg.style.display = 'inline-block';
		                e.target.closest('.fileinput-box').querySelector('.file-name').click();
		                cropper = new Cropper(modalImg, {
		                    viewMode: 3,
		                    aspectRatio: 16/9,
		                });
		                // imgItem.setAttribute('src', event.target.result);
		                // imgItem.style.display = 'block';

		            });

		            /*cancell uploading on close modal window*/
		            const closeModalBtn = modal.querySelector('.close-popup');

		            const closeModalHandler = () => {
		                cropper.destroy();
		                e.target.value = '';
		            }

		            closeModalBtn.addEventListener('click', closeModalHandler);

		            /*save image*/


		            const saveModalBtn = modal.querySelector('.save');

		            const saveModalBtnHandler = () => {
		                modal.classList.remove('active');
		                const imgSrc = cropper.getCroppedCanvas().toDataURL();
		                
		                const inputHidden = imgItem.closest('.fileinput-box-crop').querySelector('input[type=hidden]');
		                const inputCropParent = imgItem.closest('.fileinput-box-group');

		                if(inputCropParent){
		                	const fileinputimg = document.createElement('img');
		                	fileinputimg.className = 'fileinput-box-img';
		                	fileinputimg.src = imgSrc;
		                	const currentFileInputImg = inputCropParent.querySelector('.fileinput-box-img');
		                	if(currentFileInputImg){
		                		currentFileInputImg.remove();
		                	}
		                	inputCropParent.prepend(fileinputimg);
		                }

		                if(inputHidden){
		                	imgItem.closest('.fileinput-box-crop').querySelector('input[type=hidden]').value = imgSrc;
		                }
		                

		                cropper.destroy();
		                saveModalBtn.removeEventListener('click', saveModalBtnHandler);
		            }

		            saveModalBtn.addEventListener('click', saveModalBtnHandler);

	        	}
	        }


	    }
	    fileInput.forEach(item => item.addEventListener('change', changeFileInputHandler))


	    /*================END PHOTOEDITOR==============*/


		/*Toggle account-menu*/

		const toggleBtn = document.querySelectorAll('.toggle-account-menu');

		const clickToggleBtnHandler = (e) => {
			e.preventDefault();
			let accountMenu = document.querySelector(e.target.closest('.toggle-account-menu').getAttribute('for'));
			if (accountMenu){
				accountMenu.classList.toggle("show");
			}
		}

		if(toggleBtn){
			toggleBtn.forEach(item => item.addEventListener('click', clickToggleBtnHandler));
		}

		const closeAccountMenu = (e) => {
			if(e.target.closest('.toggle-account-menu') == null
				&& e.target.closest('.account-menu') == null){
				document.querySelectorAll('.account-menu').forEach(item => item.classList.remove("show"));

				const currentLevels = document.querySelectorAll('.level.current');
				currentLevels.forEach(item => {
					if (!item.classList.contains('level-1')){
						item.classList.remove('current');
					}
				});
			}
		}

		if (accountMenu){
			document.addEventListener("click", closeAccountMenu);
		}

		const openLvlBtn = document.querySelectorAll('.open-level');
		if (openLvlBtn){
			const openLevelHandler = (e) => {
				const btn = e.target.closest('.open-level');
				btn.parentNode.querySelector('.level').classList.add('current');
			}

			openLvlBtn.forEach(item => item.addEventListener('click', openLevelHandler));
		}


		const backBtn = document.querySelectorAll('.back-btn');
		if (backBtn){
			const backBtnHandler = (e) => {
				e.target.closest('.current').classList.remove('current');
			}

			backBtn.forEach(item => item.addEventListener('click', backBtnHandler));
		}



		/*clear input*/

		const inputBox = document.querySelectorAll('.input-box');

		if(inputBox){
			inputBox.forEach(item => {

				const clearBtn = item.querySelector('.clear-btn')

				const clearBtnClickHandler = (e) => {
					if(clearBtn){
						clearBtn.previousElementSibling.value = '';
						clearBtn.classList.add('hide');
					}
				}	

				


				const searchResultInputFocusListener = () => {
					if(clearBtn){
						if (item.querySelector('input').value != ''){
							clearBtn.classList.remove("hide");
						}else{
							clearBtn.classList.add("hide");
						}
					}
				}

				searchResultInputFocusListener();

				if(clearBtn){
					item.querySelector('.clear-btn').addEventListener('click', clearBtnClickHandler);
				}

				item.querySelector('input').addEventListener('input', searchResultInputFocusListener);
				item.querySelector('input').addEventListener('focusin', searchResultInputFocusListener);
			});
		}

		/*Range slider*/

		const rangeSlider = document.querySelectorAll('.slider-distance');
		const rangeSliderFrom = document.querySelectorAll('.slider-distance .from');
		const rangeSliderTo = document.querySelectorAll('.slider-distance .to');

		const renderRangeFromSlider = (el) => {
			el.value=Math.min(el.value,el.parentNode.childNodes[5].value-1);
			var value=(100/(parseInt(el.max)-parseInt(el.min)))*parseInt(el.value)-(100/(parseInt(el.max)-parseInt(el.min)))*parseInt(el.min);
			var parent = el.closest('.slider-distance');

			parent.querySelector('.inverse-left').style.width=value+'%';
			parent.querySelector('.range').style.left=value+'%';
			parent.querySelector('.thumb-left').style.left=value+'%';
			parent.querySelector('.from-value').style.left=value+'%';

			parent.querySelector('.from-value').querySelector('.value').innerHTML=el.value;
		}
		const renderRangeToSlider = (el) => {
			el.value=Math.max(el.value,el.parentNode.childNodes[3].value-(-1));
			var value=(100/(parseInt(el.max)-parseInt(el.min)))*parseInt(el.value)-(100/(parseInt(el.max)-parseInt(el.min)))*parseInt(el.min);
			var parent = el.closest('.slider-distance');
			parent.querySelector('.inverse-right').style.width=(100-value)+'%';
			parent.querySelector('.range').style.right=(100-value)+'%';
			parent.querySelector('.thumb-right').style.left=value+'%';

			parent.querySelector('.to-value').style.left=value+'%';
			parent.querySelector('.to-value').querySelector('.value').innerHTML=el.value;
		}

		const rangeSliderFromInputListener = (e) => {
			renderRangeFromSlider(e.target);
		}
		rangeSliderFrom.forEach(item => item.addEventListener('input', rangeSliderFromInputListener));
		rangeSliderFrom.forEach(function(e){
			renderRangeFromSlider(e);
		});

		const rangeSliderToInputListener = (e) => {
			renderRangeToSlider(e.target);
		}

		
		rangeSliderTo.forEach(item => item.addEventListener('input', rangeSliderToInputListener));
		rangeSliderTo.forEach(function(e){
			renderRangeToSlider(e);
		});



		



		/*File input*/

		const fileInputBox = document.querySelectorAll('.fileinput-box');

		const updateFileInput = (item) => {
			const input = item.querySelector('input[type=file]');
			const label = item.querySelector('.file-name');
			const icon = item.querySelector('i.add-file');
			const clearBtn = item.querySelector('.clear-btn');

			const updateClearBtn = () => {
				if (input.files.length > 0){
					clearBtn.classList.remove('hide');
				}else{
					clearBtn.classList.add('hide');
				}
			}

			const setDefaultPhoto = () => {
				const imgholder = item.closest('.fileinput-box-group');
				if(imgholder){
					const img = imgholder.querySelector('.fileinput-box-img');

					if(img){
						img.src = '../img/default_photo.png';
					}
				}

			}

			input.addEventListener('change', e => {
				if(input.files.length > 1){
					let labelText = '';
					Object.keys(input.files).forEach(key => {
						if (labelText == ''){
							labelText += input.files[key].name;
						}else{
							labelText += ', '+input.files[key].name;
						}
					});
					label.innerHTML = labelText;
				}else{
					label.innerHTML = '../'+input.files[0].name;
				}


				
				icon.classList.add("added");
				if(clearBtn){
					updateClearBtn();
				}
			});

			if (item.closest('.fileinput-box-group')){
				input.addEventListener('change', e => {
					let el = e.target || window.event.srcElement,
	        			files = el.files;

	        		if (FileReader && files && files.length) {
	        			let fr = new FileReader();
				        fr.onload = function () {
				        	const img = item.closest('.fileinput-box-group').querySelector('.fileinput-box-img');
				            
				            if(img){
					            img.src = fr.result;
					        }
				        }
				        fr.readAsDataURL(files[0]);
	        		}


					label.innerHTML = '../'+input.files[0].name;
					icon.classList.add("added");
					if(clearBtn){
						updateClearBtn();
					}
					
					e.target.value = '';
				});
			}

			if(clearBtn){
				clearBtn.addEventListener('click', e => {
					input.value = '';
					label.innerHTML = label.getAttribute('data-empty-val');
					icon.classList.remove("added");
					updateClearBtn();
					setDefaultPhoto();
				});
			}
			
			if(clearBtn){
				updateClearBtn();
			}
			
		}
		
		fileInputBox.forEach(item => {
			updateFileInput(item);
		});


		/*Toggler*/

		const stars = document.querySelectorAll('.star');

		const clickStarHandler = (e) => {
			const elToToggle = e.target.closest('.stars').getAttribute('for');
			if(elToToggle){
				document.getElementById(elToToggle).classList.remove('toggle-hide');
			}
			const currentStars = e.target.closest('.stars').querySelectorAll('.star');
			currentStars.forEach(item => item.classList.remove('active'));

			const nodes = Array.prototype.slice.call(currentStars);
			const nodeIndex = nodes.indexOf(e.target);

			currentStars.forEach( (item, index) => {
				if (index <= nodeIndex){
					item.classList.add('active');
				}
			});
		}
		if(stars){
			stars.forEach(item => item.addEventListener("click", clickStarHandler));
		}


		/*Gallery*/
		const galleryCards = document.querySelectorAll('.gallery-card-items .gallery-card');
		galleryCards.forEach((el, index) => {
			let galleryArrowLeft = el.closest('.gallery-card-items').parentElement.querySelector('.arrow-left');
			let galleryArrowRight = el.closest('.gallery-card-items').parentElement.querySelector('.arrow-right');
			el.addEventListener('click', function(){
				if(el.closest('.slider').querySelector(':scope > img.default')){
					el.closest('.slider').querySelector(':scope > img.default').remove();
				}
				const galleryItems = el.closest('.gallery-card-items').parentElement.querySelector('.popup .gallery-holder .gallery .items');
				const sliderItems = el.closest('.gallery-card-items').querySelectorAll('.item');
				galleryItems.innerHTML = '';

				sliderItems.forEach((item, slIndex) => {
					let itemsToAppend = item.cloneNode(true);
					itemsToAppend.classList.remove('active');
					if(slIndex == index){
						itemsToAppend.classList.add('active');
					}
					galleryItems.appendChild(itemsToAppend.cloneNode(true));
				});

				el.closest('.gallery-card-items').parentElement.querySelector('.popup').classList.add('active');

				

				const galleryArrowRightClickHandler = (event) => {
					event.stopImmediatePropagation();
					let activeElement = galleryItems.querySelector('.item.active');
					let nextActive = activeElement.nextElementSibling;
					
					activeElement.classList.remove('active');
					console.log(nextActive)
					if(nextActive != null){
						nextActive.classList.add("active");
					}else{
						galleryItems.querySelectorAll('.item')[0].classList.add('active');
					}
				}
				galleryArrowRight.addEventListener('click', galleryArrowRightClickHandler);

				const galleryArrowLeftClickHandler = () => {
					event.stopImmediatePropagation();
					let activeElement = galleryItems.querySelector('.item.active');
					activeElement.classList.remove('active');
					let prevActive = activeElement.previousElementSibling;
					if(prevActive != null){
						prevActive.classList.add("active");
					}else{
						galleryItems.querySelectorAll('.item')[galleryItems.querySelectorAll('.item').length-1].classList.add('active');
					}
				}
				galleryArrowLeft.addEventListener('click', galleryArrowLeftClickHandler);
			});
		});

		const itemInputBox = document.querySelectorAll('.item-input-box');

		if(itemInputBox){
			itemInputBox.forEach(item => {
				const clearBtn = item.querySelector('.item > .clear-btn');

				if (clearBtn){
					const clearBtnClickHandler = (e) => {
						e.target.closest('.item').remove();
					}

					clearBtn.addEventListener('click', clearBtnClickHandler);
				}
			});
		}



		/*Databox input*/

		const dataBoxes = document.querySelectorAll('.data-box');

		const dataBoxesAddBtnClickHandler = (e) => {
			if(e.target.closest('.data-box').querySelector('.add-panel')){
				e.target.closest('.data-box').querySelector('.add-panel').classList.remove('hide');
			}
		}

		const dataBoxClearBtnClickHandler = (e) => {
			const dataItem = e.target.closest('.data-item');
			const dataSelect = dataItem.closest('.data-box').querySelector('select');
			if(dataSelect){
				const dataName = dataItem.querySelector('.item-value').getAttribute('for');
				const itemToRemove = dataSelect.querySelector('option[value="'+dataName+'"]');
				if(itemToRemove){
					itemToRemove.remove();
				}
			}
			if(dataItem){
				dataItem.remove();
			}
		}

		var callDataBoxClearBtn = () => {
			document.querySelectorAll('.data-box .data-item > .clear-btn').forEach(item => item.addEventListener('click', dataBoxClearBtnClickHandler));
		}

		var clearDataBoxDataFun = (closestDataBox) => {
			const clearDataBoxData = (event) => {
		    	const elToRemove = event.target.closest('.data-item').querySelector('.item-value').innerHTML;
		    	closestDataBox.querySelectorAll(':scope > select option').forEach(item => {
		    		if(item.text == elToRemove){
		    			item.remove();
		    		}
		    	});
		    }
		    closestDataBox.querySelectorAll('.data-item > .clear-btn').forEach(item => item.addEventListener('click', clearDataBoxData));
		}

		const dataBoxLinkClickHandler = (e) => {

			if(e.target.closest('.data-box').classList.contains('data-box-input')){
				const addPanelInput = e.target.closest('.add-panel').querySelector('input');
				const addPanelSelect = e.target.closest('.add-panel').querySelector('select');

				let boxValue, addPanelEl = '';

				if (addPanelInput){
					addPanelEl = addPanelInput;
					boxValue = addPanelEl.value;
				} else if (addPanelSelect){
					addPanelEl = addPanelSelect;
					boxValue = addPanelEl.options[addPanelEl.selectedIndex].text;
				}

				

				if(boxValue.length > 0){
					let closestDataBox = e.target.closest('.data-box');
					let hasSimilarInside = false;
					closestDataBox.querySelectorAll(':scope > select option').forEach(item => {
							if (item.text == boxValue){
								hasSimilarInside = true;
							}
						}
					)
					if(!hasSimilarInside){
						closestDataBox.querySelector('.data-list').innerHTML +=`
							<div class="data-item">
								<span class="dark-text item-value" for="${boxValue}">${boxValue}</span>
								<div class="clear-btn"></div>
							</div>
						`;

						const optSelect = closestDataBox.querySelector(':scope > select');
						const opt = document.createElement('option');
					    opt.setAttribute('selected', 'selected');
					    opt.value = boxValue;
					    opt.innerHTML = boxValue;
					    

					    if(optSelect){
					    	optSelect.appendChild(opt);
					    }
						
					    
					    clearDataBoxDataFun(closestDataBox);
						callDataBoxClearBtn();
						addPanelEl.value = '';
						if (addPanelSelect){
							addPanelSelect.querySelectorAll('option').forEach(item => item.selected = false);
							addPanelSelect.closest('.select-box').querySelector('.select-items .selected').classList.remove('selected');
							addPanelSelect.closest('.select-box').querySelector('.select-selected').innerHTML = '';
						}
						closestDataBox.querySelector('.add-panel').classList.add('hide');
					}
				}
			}

			if(e.target.closest('.data-box').classList.contains('data-box-social-links')){
				e.target.closest('.data-box').querySelector('.data-list').innerHTML = '';
				e.target.closest('.add-panel').querySelectorAll('.social-links .item').forEach(item => {
					const boxValue = item.querySelector('input').value;
					if(boxValue.length > 0){
						const icon = item.querySelector('i').outerHTML;
						e.target.closest('.data-box').querySelector('.data-list').innerHTML +=`
							<div class="data-item">
								${icon}
								<span>${boxValue}</span>
								<div class="clear-btn"></div>
							</div>
						`;
					}
				});
				callDataBoxClearBtn();
				e.target.closest('.data-box').querySelector('.add-panel').classList.add('hide');
			}

			

			if(e.target.closest('.data-box').classList.contains('data-box-fileinput')){
				const databox = e.target.closest('.data-box');
				if(databox.querySelector('.add-panel .input-text input').value.length > 0){
					databox.querySelector('.add-panel').classList.add('hide');
					databox.querySelector('.add-panel .file-label').innerText = databox.querySelector('.add-panel .input-text input').value;
					const itemToClone = databox.querySelector('.add-panel .item-to-clone').cloneNode(true);
					

					itemToClone.querySelector('.input-text input').type = 'hidden';
					itemToClone.classList.remove('item-to-clone');
					if(itemToClone.querySelector){
						itemToClone.querySelector('.link').remove();
					}
					
					databox.querySelector('.data-list').appendChild(itemToClone);

					databox.querySelectorAll('.data-list .fileinput-box').forEach(item => updateFileInput(item));
					databox.querySelector('.add-panel .input-text input').value = '';
					databox.querySelector('.add-panel input[type="file"]').value = '';
					if(databox.querySelector(".add-panel .row .clear-btn")){
						databox.querySelector(".add-panel .row .clear-btn").classList.add('hide');
					}
					
					databox.querySelector(".add-panel .add-file").classList.remove('added');
					databox.querySelector(".add-panel .file-name").innerHTML = databox.querySelector(".add-panel .file-name").getAttribute('data-empty-val');
					callDataBoxClearBtn();
				}
			}
			
		}

		callDataBoxClearBtn();

		if(dataBoxes){
			dataBoxes.forEach(item => {
				if(item.querySelector('.add')){
					item.querySelector('.add').addEventListener('click', dataBoxesAddBtnClickHandler);
				}

				if(item.querySelector(':scope > select')){
					clearDataBoxDataFun(item);
				}
				
				if(item.querySelector('.add-panel .link')){
					item.querySelector('.add-panel .link').addEventListener('click', dataBoxLinkClickHandler);
				}


			});
		}

		const activeClass = 'active';
		const closePopup = document.querySelectorAll('.close-popup');

		if(closePopup.length > 0) {
			const closePopupBtnClickHandler = (event) => {
				event.target.closest('.popup').classList.remove(activeClass);
			}
			closePopup.forEach( item => item.addEventListener('click', closePopupBtnClickHandler));
		}

		const togglePopup = document.querySelectorAll('.toggle-popup');
		if(togglePopup){
			const togglePopupBtnClickHandler = (event) => {
				const datasetpopup = event.target.dataset.popup;
				if(datasetpopup.length > 0){
					const popupToToggle = document.getElementById(datasetpopup);
					popupToToggle.classList.add(activeClass);
				}
				
			}
			togglePopup.forEach(item => item.addEventListener('click', togglePopupBtnClickHandler));
		}


		// count how many day in month
		var getDays = (year, month) => new Date(year, month, 0).getDate();

		//add leading zero to number
		var addLeadingZero = (num) => String(num).padStart(2, '0');


		/*Calendar list*/

		// replace last element of array
		var replaceLastElement = (arrayToChange) => {
		  arrayToChange.unshift(arrayToChange.pop());
		  return arrayToChange;
		}


		//After fill btn select

		let afterfillbtnSelect = document.querySelectorAll('.afterfill-btn-holder select');

		afterfillbtnSelect.forEach(item => {
			

			item.addEventListener('change', (event) => {
			 	let afterfillHolder = event.target.closest('.afterfill-btn-holder');
			 	afterfillHolder.classList.add('shown');
			});
		});

		let afterfillbtn = document.querySelectorAll('.afterfill-btn-holder .afterfill-btn');

		afterfillbtn.forEach(item => {
			item.addEventListener('click', (event) => {
			 	let afterfillHolder = event.target.closest('.afterfill-btn-holder');
			 	afterfillHolder.classList.remove('shown');
			});
		});
	}


	translatePage(langobj);
	mainScripts();
});